export default function (
  $stateProvider,
  $urlRouterProvider,
  $urlMatcherFactoryProvider
) {
  $urlMatcherFactoryProvider.strictMode(false);
  $urlRouterProvider.otherwise(function ($injector, $location) {
    return '/404?previousPath=' + $location.url();
  });

  // Components
  window.templates = {
    login: require('../components/login/login.html'),
    tree_uses: require('../components/tree_uses/index.html'),
    ecommerce_tree: require('../components/ecommerce_tree/index.html'),
    ecommerce_tree_preview: require('../components/ecommerce_tree/ecommerce_tree_preview.html'),
    // horoscope_cirle_tree: require('../components/ecommerce_tree/horoscopeCirleTree.html'),
    // horoscopeDialer: require('../components/horoscopeDialer/horoscopeDialer.html'),
    // horoscopeMenu: require('../components/horoscopeDialer/horoscopeMenu.html'),
    ecommerce_pack: require('../components/ecommerce_pack/index.html'),
    ecommerce_voucher: require('../components/ecommerce_voucher/index.html'),
    statsNumbers_list: require('../components/statsNumbers/list.html'),
    statsNumbers_projectsPage: require('../components/statsNumbers/projectsPage.html'),
    nums_users: require('../components/nums/users.html'),
    nums_emission: require('../components/nums/emission.html'),
    nums_trees: require('../components/nums/trees.html'),
    nums_countries: require('../components/nums/countries.html'),
    nums_projects: require('../components/nums/projects.html'),
    nums_organizations: require('../components/nums/organizations.html'),
    adv: require('../components/adv/index.html'),
    banners: require('../components/banners/bannerSidebar.html'),
    previewCart: require('../components/cart/previewCart.html'),
    newCart: require('../components/cart/newCart.html'),
    newCartItemPrivate: require('../components/cart/newCartItemPrivate.html'),
    newCartItemVoucher: require('../components/cart/newCartItemVoucher.html'),
    newCartItemBusiness: require('../components/cart/newCartItemBusiness.html'),
    newCartVoucher: require('../components/cart/newCartVoucher.html'),
    treeCollection: require('../components/treeCollection/index.html'),
    viewPeopleCard: require('../components/viewPeopleCard/index.html'),
    userTree: require('../components/userTree/index.html'),
    userTreePlant: require('../components/userTree/plant.html'),
    userTreeRally: require('../components/userTree/rally.html'),
    userTreeSeeAll: require('../components/userTree/seeAll.html'),
    comments: require('../components/comments/index.html'),
    post: require('../components/post/index.html'),
    loading: require('../components/loading/index.html'),
    loadingPage: require('../components/loadingPage/index.html'),
    ecommerceBox: require('../components/ecommerceBox/index.html'),
    cartBadges: require('../components/cartBadges/index.html'),
    cartPowers: require('../components/cartPowers/index.html'),
    productPowersCircles: require('../components/productPowersCircles/index.html'),
    productPowersBars: require('../components/productPowersBars/index.html'),
    emptyCart: require('../components/emptyCart/emptyCart.html'),
    partyFavorEcommerceBox: require('../components/partyFavorEcommerceBox/index.html'),
    pagesHeader: require('../components/pagesHeader/pagesHeader.html'),
    pagesHeaderCampaign: require('../components/pagesHeader/pagesHeaderCampaign.html'),
    redeemErrorCoupon: require('../components/redeemFlowTabs/redeemErrorCoupon.html'),
    redeemIntro: require('../components/redeemFlowTabs/redeemIntro.html'),
    redeemIntroOpenForest: require('../components/redeemFlowTabs/redeemIntroOpenForest.html'),
    redeemOpenForestGiftPicker: require('../components/redeemFlowTabs/redeemOpenForestGiftPicker.html'),
    redeemOpenForestDone: require('../components/redeemFlowTabs/redeemOpenForestDone.html'),
  };

  // Components
  const footer = require('../html/templates/footer.html');
  const navbar = require('../html/templates/navbar.html');

  $stateProvider
    .state('index', {
      fullRefreshNavigateTo: true,
      url: '/',
    })
    .state('auth-login', {
      fullRefreshNavigateTo: true,
      url: '/auth/login?next',
    })
    .state('login', {
      fullRefreshNavigateTo: true,
      url: '/auth/login',
    })
    .state('globalLogin', {
      url: '/globalLogin',
      seo: 'index',
      follow: false,
      onEnter: [
        '$transition$',
        function ($transition$) {
          const previousPath = $transition$.from().abstract
            ? $transition$.router.stateService.href('index')
            : $transition$.router.stateService.href(
              $transition$.from().name,
              $transition$.from().params
            );
          const { protocol, host, search } = window.location;
          const searchQuery = (search ? search + '&' : '?') + 'login';
          window.location.href =
            protocol + '//' + host + previousPath + searchQuery;
        },
      ],
    })
    .state('sign-in', {
      url: '/sign-in',
      seo: 'index',
      follow: false,
      views: {
        'page-content': {
          template: require('../html/templates/v-auth/sign-in.html'),
        },
      },
    })
    .state('impersonate', {
      fullRefreshNavigateTo: true,
      url: '/auth/impersonate/:slug',
      seo: 'index',
      follow: false,
    })
    .state('post', {
      url: '/post/:id?type?shareUrl',
      seo: 'index',
      follow: false,
      views: {
        'page-content': {
          template: require('../html/templates/post/post.html'),
        },
      },
      params: {
        type: {
          type: 'string',
        },
        shareUrl: {
          type: 'string',
        },
      },
    })
    .state('signup', {
      url: '/signup',
      seo: 'index',
      follow: false,
      onEnter: [
        '$transition$',
        function ($transition$) {
          const previousPath = $transition$.from().abstract
            ? $transition$.router.stateService.href('index')
            : $transition$.router.stateService.href(
              $transition$.from().name,
              $transition$.from().params
            );
          const { protocol, host, search } = window.location;
          const searchQuery = (search ? search + '&' : '?') + 'login';
          window.location.href =
            protocol + '//' + host + previousPath + searchQuery;
        },
      ],
    })
    .state('checkusername', {
      url: '/checkusername',
      seo: 'index',
      follow: false,
      fullRefreshNavigateTo: true,
    })
    .state('confirmmailaddress', {
      url: '/confirmmailaddress/:status',
      seo: 'index',
      follow: false,
      views: {
        'page-content': {
          template: require('../html/templates/confirm-mail-address.html'),
          controller: 'confirmMailController',
        },
        'navbar-content': {
          template: navbar,
        },
        'footer-content': {
          template: footer,
        },
      },
    })
    .state('create-your-gift', {
      fullRefreshNavigateTo: true,
      url: '/create-your-gift',
    })
    .state('create-your-gift-step', {
      fullRefreshNavigateTo: true,
      url: '/create-your-gift/:step',
      params: {
        step: { squash: true, value: null },
      },
      follow: false,
    })
    .state('workwithus', {
      url: '/workwithus',
      fullRefreshNavigateTo: true,
    })
    .state('manifesto', {
      fullRefreshNavigateTo: true,
      url: '/manifesto',
    })
    .state('agroforestry', {
      fullRefreshNavigateTo: true,
      url: '/agroforestry',
    })
    .state('ecommerce', {
      fullRefreshNavigateTo: true,
      url: '/ecommerce/home',
    })
    .state('ecommerce-switch', {
      fullRefreshNavigateTo: true,
      url: '/ecommerce/switch',
    })
    .state('subscriptions-switch', {
      fullRefreshNavigateTo: true,
      url: '/subscription/switch',
    })
    .state('ecommerce-start', {
      fullRefreshNavigateTo: true,
      url: '/ecommerce/start-now',
    })
    .state('forest-add-trees', {
      fullRefreshNavigateTo: true,
      url: '/forest/:slug/add-trees?id',
      params: {
        id: { squash: true, value: null, type: 'string' },
      },
    })
    .state('species', {
      fullRefreshNavigateTo: true,
      url: '/plant-a-tree?subscribe?sort?types?badges?countries?specie?speciesay?tags?filterTags?filterCountries?gift',
      seo: 'species',
      follow: true,
      params: {
        subscribe: { type: 'int', squash: true, value: null },
        sort: { type: 'string', squash: true, value: null, dynamic: true },
        types: {
          type: 'string',
          array: true,
          squash: true,
          value: [],
          dynamic: true,
        },
        badges: {
          type: 'int',
          array: true,
          squash: true,
          value: [],
          dynamic: true,
        },
        countries: {
          type: 'int',
          array: true,
          squash: true,
          value: [],
          dynamic: true,
        },
        filterCountries: {
          type: 'int',
          array: true,
          squash: true,
          value: [],
          dynamic: true,
        },
        specie: {
          type: 'int',
          array: true,
          squash: true,
          value: [],
          dynamic: true,
        },
        speciesay: {
          type: 'int',
          array: true,
          squash: true,
          value: [],
          dynamic: true,
        },
        tags: {
          type: 'int',
          array: true,
          squash: true,
          value: [],
          dynamic: true,
        },
        filterTags: {
          type: 'string',
          array: true,
          squash: true,
          value: [],
          dynamic: true,
        },
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
      },
    })
    .state('airc-landing', {
      url: '/treedom-airc',
      fullRefreshNavigateTo: true,
    })
    .state('species.acquisition', {
      url: '/acquisition',
      fullRefreshNavigateTo: true,
    })
    .state('species.tree', {
      fullRefreshNavigateTo: true,
      url: '/:treeId',
      seo: 'specieSingle',
      follow: true,
      params: {
        hideToolbar: { type: 'bool', value: true, dynamic: true },
      },
    })
    .state('landing-forest', {
      fullRefreshNavigateTo: true,
      url: '/forests',
      follow: false,
    })
    .state('create-forest', {
      fullRefreshNavigateTo: true,
      url: '/forest/create',
      follow: false,
      params: {
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
        hideToolbar: { type: 'bool', value: true, dynamic: true },
      },
    })
    .state('create-forest.trees', {
      fullRefreshNavigateTo: true,
      url: '/trees-forest',
    })
    .state('create-forest.pay', {
      fullRefreshNavigateTo: true,
      url: '/pay-forest',
    })
    .state('create-forest.customize', {
      fullRefreshNavigateTo: true,
      url: '/customize-forest',
    })
    .state('checkout', {
      fullRefreshNavigateTo: true,
      url: '/checkout',
      follow: false,
      params: {
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
      },
      onEnter: function () {
        window.scrollTo(0, 0);
      },
    })
    .state('checkout.login', {
      fullRefreshNavigateTo: true,
      url: '/login',
    })
    .state('checkout.business', {
      fullRefreshNavigateTo: true,
      url: '/business',
    })
    .state('checkout.payment', {
      fullRefreshNavigateTo: true,
      url: '/payment',
    })
    .state('checkout.gift', {
      url: '/gift/:cartId',
      params: {
        cartId: { squash: true, value: null },
      },
    })
    .state('subscription', {
      fullRefreshNavigateTo: true,
      url: '/subscription',
      follow: false,
      params: {
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
      },
    })
    .state('subscription.subscribe', {
      url: '/subscribe',
      onEnter: [
        '$rootScope',
        '$state',
        function ($rootScope, $state) {
          if (!($rootScope.userdata && $rootScope.userdata.subscriptionCart)) {
            return $state.target('subscription');
          }
        },
      ],
    })
    .state('subscriptions', {
      fullRefreshNavigateTo: true,
      url: '/subscriptions',
      seo: 'subscriptions',
      follow: true,
    })
    .state('settings', {
      fullRefreshNavigateTo: true,
      url: '/settings',
    })
    .state('settings.general', {
      fullRefreshNavigateTo: true,
      url: '/general',
    })
    .state('settings.general.business', {
      fullRefreshNavigateTo: true,
      url: '/business',
    })
    .state('settings.general.communication', {
      fullRefreshNavigateTo: true,
      url: '/communications',
    })
    .state('settings.signature', {
      fullRefreshNavigateTo: true,
      url: '/signature',
    })
    .state('settings.paymentMethods', {
      fullRefreshNavigateTo: true,
      url: '/payment-methods',
    })
    .state('settings.subscription', {
      fullRefreshNavigateTo: true,
      url: '/subscription',
    })
    .state('settings.widgets', {
      fullRefreshNavigateTo: true,
      url: '/widgets',
    })
    .state('settings.widgets.user-numbers', {
      fullRefreshNavigateTo: true,
      url: '/user-numbers',
    })
    .state('settings.certificate', {
      fullRefreshNavigateTo: true,
      url: '/certificate',
    })
    .state('settings.invoices', {
      fullRefreshNavigateTo: true,
      url: '/invoices',
    })
    // Private user
    .state('user', {
      url: '/user/:slug?redeemed&share',
      params: {
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
        redeemed: { type: 'string', value: '', dynamic: true, squash: true },
        share: { type: 'string', value: '', dynamic: true, squash: true },
      },
      seo: 'user',
      follow: true,
      fullRefreshNavigateTo: true,
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content': {
          template: require('../html/templates/v-user/v-user-page.html'),
        },
        'footer-content@user': {
          template: footer,
        },
      },
    })
    .state('user.post', {
      url: '/post/:postId',
      params: {
        hideToolbar: { type: 'bool', value: true, dynamic: true },
      },
      seo: 'user',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('user.map', {
      url: '/map',
      params: {
        hideToolbar: { type: 'bool', value: true, dynamic: true },
      },
      seo: 'user',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/v-user/v-user-map.html'),
        },
      },
    })
    .state('user.map.tree', {
      url: '/tree/:treeId',
      seo: 'user',
      follow: true,
    })
    .state('user.map.country', {
      url: '/:countrySlug',
      seo: 'user',
      follow: true,
    })
    .state('user.map.plant', {
      url: '/plant',
      seo: 'user',
      follow: true,
    })
    .state('user.map.plant.country', {
      url: '/:countrySlug',
      seo: 'user',
      follow: true,
    })
    .state('user.trees', {
      url: '/trees?tab',
      seo: 'user',
      follow: true,
      params: {
        tab: { type: 'string', squash: true, value: null, dynamic: true },
      },
    })
    .state('user.badges', {
      url: '/badges/:badge',
      params: {
        badge: { squash: true, value: null },
      },
      seo: 'user',
      follow: true,
    })
    .state('user.subscription', {
      url: '/subscription',
      seo: 'user',
      follow: true,
    })
    .state('user.forests', {
      url: '/forests',
      seo: 'user',
      follow: true,
    })
    .state('user.trees.item', {
      url: '/:item?redeemed',
      fullRefreshNavigateTo: true,
    })
    .state('user.trees.item.post', {
      url: '/post/:postId',
      seo: 'userTree',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    // support old url
    .state('user.trees.item.identity', {
      url: '/identity',
      seo: 'userTree',
      follow: true,
    })
    .state('user.trees.item.co2', {
      url: '/co2',
      seo: 'userTree',
      follow: true,
    })
    .state('user.trees.item.info', {
      url: '/info',
      seo: 'userTree',
      follow: true,
    })
    .state('user.trees.item.messages', {
      url: '/messages',
      seo: 'userTree',
      follow: true,
    })
    // Private event
    .state('user.event', {
      url: '/event/:eventslug',
      redirectTo: 'forest',
    })
    .state('user.event.map', {
      url: '/map',
      seo: 'user',
      follow: true,
      params: {
        hideToolbar: { type: 'bool', value: true, dynamic: true },
      },
      views: {
        'page-content@': {
          template: require('../html/templates/v-forest-map.html'),
        },
        'footer-content@': {
          template: null,
        },
      },
    })
    .state('user.event.map.tree', {
      url: '/tree/:treeId',
      seo: 'user',
      follow: true,
    })
    .state('user.event.trees', {
      url: '/trees',
      seo: 'user',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/forest/v-trees.html'),
        },
        'footer-content@': {
          template: footer,
        },
      },
    })
    .state('user.event.trees.country', {
      url: '/:countryId',
      seo: 'user',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/forest/v-trees.html'),
        },
        'footer-content@': {
          template: footer,
        },
      },
    })
    .state('user.event.users', {
      url: '/users',
      seo: 'user',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/forest/v-users.html'),
        },
        'footer-content@': {
          template: footer,
        },
      },
    })
    .state('user.event.trees.item', {
      url: '/:item?share',
      params: {
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
        share: { type: 'string', value: '', dynamic: true, squash: true },
      },
      seo: 'userTree',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/v-tree-page.html'),
        },
        'footer-content@': {
          template: footer,
        },
      },
    })
    .state('user.event.trees.item.info', {
      url: '/info',
      seo: 'userTree',
      follow: true,
    })
    .state('userGiftDetail', {
      url: '/gift-details',
      fullRefreshNavigateTo: true,
    })
    // Business user
    .state('organization', {
      url: '/organization/:slug',
      seo: 'user',
      follow: true,
      fullRefreshNavigateTo: true,
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content': {
          template: require('../html/templates/v-organization/v-organization-page.html'),
        },
        'footer-content@organization': {
          template: footer,
        },
      },
    })
    .state('organization.users', {
      url: '/users',
      params: {
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
      },
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content@': {
          template: require('../html/templates/v-organization/v-organization-users.html'),
        },
        'footer-content@organization': {
          template: footer,
        },
      },
    })
    .state('organization.impacts', {
      url: '/impacts',
      seo: 'user',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('organization.map', {
      url: '/map',
      params: {
        hideToolbar: { type: 'bool', value: true, dynamic: true },
      },
      seo: 'user',
      follow: true,
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content@': {
          template: require('../html/templates/v-organization/v-organization-map.html'),
        },
      },
    })
    .state('organization.map.events', {
      url: '/event',
      seo: 'userMap',
      follow: true,
    })
    .state('organization.map.events.item', {
      url: '/:eventSlug',
      seo: 'userMap',
      follow: true,
    })
    .state('organization.map.events.item.tree', {
      url: '/tree/:treeId',
      seo: 'userMap',
      follow: true,
    })
    .state('organization.map.countries', {
      url: '/countries',
      seo: 'userMap',
      follow: true,
    })
    .state('organization.map.countries.item', {
      url: '/:countrySlug',
      seo: 'userMap',
      follow: true,
    })
    .state('organization.map.countries.item.tree', {
      url: '/tree/:treeId',
      seo: 'userMap',
      follow: true,
    })
    .state('organization.wall', {
      url: '/wall?tab&share',
      seo: 'user',
      follow: true,
      params: {
        tab: { type: 'string', squash: true, value: null, dynamic: true },
        share: { type: 'string', value: '', dynamic: true, squash: true },
      },
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content@': {
          template: require('../html/templates/v-organization/v-organization-page.html'),
        },
        'footer-content@organization': {
          template: footer,
        },
      },
    })
    .state('organization.wall.post', {
      url: '/post/:postId',
      seo: 'user',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('organization.forests', {
      url: '/forests?tab',
      seo: 'user',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('organization.kit', {
      url: '/communication-kit?tab',
      seo: 'user',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('organization.nursery', {
      url: '/nursery/:projectId',
      seo: 'userNursery',
      follow: true,
    })
    .state('organization.nursery.specie', {
      url: '/:treeId',
      seo: 'userNursery',
      follow: true,
    })
    .state('organization.trees', {
      url: '/trees?tab&country',
      seo: 'user',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('organization.trees.item', {
      url: '/:item?share',
      fullRefreshNavigateTo: true,
    })
    .state('organization.trees.item.post', {
      url: '/post/:postId',
      seo: 'userTree',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('organization.trees.item.info', {
      url: '/info?share',
      seo: 'userTree',
      follow: true,
    })
    .state('organization.trees.item.messages', {
      url: '/messages?share',
      seo: 'userTree',
      follow: true,
    })
    // Business event
    .state('organization.event', {
      url: '/event/:eventslug',
      redirectTo: 'forest',
    })
    .state('organization.event.map', {
      url: '/map',
      seo: 'userEvent',
      follow: true,
      params: {
        hideToolbar: { type: 'bool', value: true, dynamic: true },
      },
      views: {
        'page-content@': {
          template: require('../html/templates/v-forest-map.html'),
        },
        'footer-content@': {
          template: null,
        },
      },
    })
    .state('organization.event.map.tree', {
      url: '/tree/:treeId',
      seo: 'user',
      follow: true,
    })
    .state('organization.event.trees', {
      url: '/trees',
      seo: 'user',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/forest/v-trees.html'),
        },
        'footer-content@': {
          template: footer,
        },
      },
    })
    .state('organization.event.trees.country', {
      url: '/:countryId',
      seo: 'user',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/forest/v-trees.html'),
        },
        'footer-content@': {
          template: footer,
        },
      },
    })
    .state('organization.event.users', {
      url: '/users',
      seo: 'user',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/forest/v-users.html'),
        },
        'footer-content@': {
          template: footer,
        },
      },
    })
    .state('organization.event.trees.item', {
      url: '/:item?share',
      params: {
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
        share: { type: 'string', value: '', dynamic: true, squash: true },
      },
      seo: 'userTree',
      follow: true,
      views: {
        'page-content@': {
          template: require('../html/templates/v-tree-page.html'),
        },
        'footer-content@': {
          template: footer,
        },
      },
    })
    .state('organization.event.trees.item.info', {
      url: '/info',
      seo: 'userTree',
      follow: true,
    })
    .state('openforest_redeem', {
      url: '/organization/:slug/event/:eventslug/redeem',
      seo: 'user',
      views: {
        'page-content': {
          template: require('../html/templates/event/v-openforest-redeem.html'),
        },
        'footer-content': null,
      },
    })
    .state('openforest_presentation', {
      url: '/organization/:slug/event/:eventslug/presentation',
      seo: 'user',
      views: {
        'page-content': {
          template: require('../html/templates/event/open-forests-presentation.html'),
          controller: 'openForestsPresentationController',
        },
      },
    })
    // Nazioni
    .state('countries', {
      fullRefreshNavigateTo: true,
      url: '/countries',
      seo: 'projects',
      follow: true,
    })
    .state('countries-archived', {
      fullRefreshNavigateTo: true,
      url: '/countries/archived',
      seo: 'projects',
      follow: true,
    })
    .state('country', {
      fullRefreshNavigateTo: true,
      url: '/countries/:slug',
      seo: 'project',
      follow: true,
      params: {
        avoidVueUnmount: { type: 'bool', value: true, dynamic: true },
      },
    })
    // Progetti
    .state('projects', {
      url: '/projects',
      seo: 'projects',
      follow: true,
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content': {
          template: require('../html/templates/twoColumn.html'),
          controller: 'projectsController as projectsCtrl',
        },
        'twoColHeader@projects': {
          template: require('../html/templates/projects/header.html'),
        },
        'twoColCenter@projects': {
          template: require('../html/templates/projects/main.html'),
        },
        'footer-content': {
          template: footer,
        },
      },
    })
    // Singolo paese
    .state('project', {
      url: '/projects/:slug',
      redirectTo: 'countries', // avoids opening concluded or empty country page
    })
    .state('project.tree', {
      url: '/tree/:treeId',
      redirectTo: 'countries', // avoids opening concluded or empty country page
    })
    .state('project.gallery', {
      url: '/gallery',
      redirectTo: 'countries', // avoids opening concluded or empty country page
    })
    .state('project.users', {
      url: '/users',
      redirectTo: 'countries', // avoids opening concluded or empty country page
    })
    .state('project.posts', {
      url: '/posts',
      redirectTo: 'countries', // avoids opening concluded or empty country page
    })
    .state('project.posts.post', {
      url: '/:postSlug',
      redirectTo: 'countries', // avoids opening concluded or empty country page
    })
    // Gift a tree
    .state('giftATree', {
      fullRefreshNavigateTo: true,
      url: '/gift-a-tree',
      seo: 'gift-a-tree',
      follow: true,
      views: {},
    })
    .state('howToGift', {
      fullRefreshNavigateTo: true,
      url: '/how-to-gift',
      seo: 'gift-a-tree',
      follow: true,
      views: {},
    })
    // iframe
    .state('iframeGenerator', {
      url: '/iframe-generator',
      seo: 'index',
      follow: false,
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content': {
          template: require('../html/templates/iframe/generator.html'),
          controller: 'iframeGeneratorController',
        },
        'footer-content': {
          template: footer,
        },
      },
    })
    .state('iframecode', {
      url: '/iframe/code',
      seo: 'index',
      follow: true,
      views: {
        'page-content': {
          template: require('../html/templates/iframe/code.html'),
          controller: 'iframeGeneratorController',
        },
      },
    })
    .state('iframeNumbersEvent', {
      url: '/iframe/numbers/event/:id',
      fullRefreshNavigateTo: true,
    })
    .state('iframeNumbersUser', {
      url: '/iframe/numbers/user/:id',
      seo: 'index',
      follow: true,
      views: {
        'page-content': {
          template: require('../html/templates/iframe/numbers.html'),
          controller: 'iframeGeneratorController',
        },
      },
    })
    .state('iframe', {
      url: '/iframe/:type/:user',
      seo: 'index',
      follow: true,
      views: {
        'page-content': {
          template: require('../html/templates/iframe/index.html'),
        },
        'mapPrivate@iframe': {
          template: require('../html/templates/newmap.html'),
          controller: 'newMapController as newMapCtrl',
        },
        'mapBusiness@iframe': {
          template: require('../html/templates/newmap_business.html'),
          controller: 'newMapBusinessController as newMapBusinessCtrl',
        },
      },
    })
    .state('game', {
      url: '/game/:treecode',
      seo: 'treecode',
      params: {
        treecode: { squash: true, value: null },
      },
      views: {
        'page-content': {
          template: require('../html/templates/v-game.html'),
        },
      },
    })
    .state('gameComplete', {
      url: '/game/:treecode/complete/:rewardUuid',
      seo: 'treecode',
      params: {
        treecode: { squash: true, value: null },
        rewardUuid: { squash: true, value: null },
      },
      views: {
        'page-content': {
          template: require('../html/templates/v-gameComplete.html'),
        },
      },
    })
    .state('treecodeLanding', {
      url: '/treecode',
      params: {
        error: { squash: true, value: null },
      },
      seo: 'treecodeRedeem',
      follow: false,
      fullRefreshNavigateTo: true,
    })
    .state('treecode', {
      fullRefreshNavigateTo: true,
      url: '/treecode/:treecode',
      params: {
        treecode: { squash: true, value: null },
      },
      seo: 'treecode',
      follow: false,
      views: {},
    })
    .state('holidayQuiz', {
      fullRefreshNavigateTo: true,
      url: '/co2/holiday-calculator',
    })
    .state('co2-calculator',{
      url: '/landing/co2-calculator',
      fullRefreshNavigateTo: true,
    })
    .state('emission', {
      url: '/co2',
      seo: 'emission',
      follow: true,
      redirectTo: 'co2-calculator',
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content': {
          template: require('../html/templates/twoColumn.html'),
          controller: 'emissionController as emissionCtrl',
        },
        'twoColHeader@emission': {
          template: require('../html/templates/emission/header.html'),
        },
        'twoColCenter@emission': {
          template: require('../html/templates/emission/main.html'),
        },
        'footer-content@emission': {
          template: footer,
        },
      },
    })
    .state('team', {
      fullRefreshNavigateTo: true,
      url: '/team',
      seo: 'team',
      follow: true,
    })
    .state('aboutUs', {
      url: '/about-us',
      seo: 'about-us',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('page', {
      url: '/page',
      seo: 'index',
      follow: true,
      redirectTo: 'index',
      views: {
        'navbar-content': {
          template: navbar,
        },
        'twoColHeader@page': {
          template: require('../html/templates/static/header.html'),
        },
        'page-content': {
          template: require('../html/templates/twoColumn.html'),
          controller: 'customerCareController',
        },
        'twoColCenter@page': {
          template: require('../html/templates/static/main.html'),
        },
        'footer-content': {
          template: footer,
        },
      },
    })
    .state('page.privacy', {
      url: '/privacy',
      seo: 'index',
      follow: false,
      fullRefreshNavigateTo: true,
    })
    .state('page.cookie', {
      url: '/cookie',
      seo: 'index',
      follow: false,
      fullRefreshNavigateTo: true,
    })
    .state('terms', {
      url: '/terms',
      seo: 'index',
      follow: false,
      fullRefreshNavigateTo: true,
    })
    .state('page.terms', {
      url: '/terms',
      redirectTo: 'terms',
    })
    .state('contacts', {
      fullRefreshNavigateTo: true,
      url: '/contacts',
      seo: 'contacts',
      follow: true,
    })
    .state('page.register', {
      url: '/register?id',
      seo: 'index',
      follow: true,
      views: {
        'static-content': {
          template: require('../html/templates/static/pages/tree-register.html'),
          controller: 'treeRegisterController as treeRegisterCtrl',
        },
      },
    })
    .state('legals', {
      url: '/legals',
      seo: 'legals',
      follow: true,
      fullRefreshNavigateTo: true,
    })
    .state('blog', {
      url: '/blog',
      seo: 'blog',
      onEnter: [
        '$rootScope',
        '$window',
        function ($rootScope, $window) {
          $window.open('//blog.treedom.net/' + $rootScope.ln, '_self');
        },
      ],
    })
    .state('blog.post', {
      url: '/post/:slug',
      seo: 'blog',
      onEnter: [
        '$rootScope',
        '$window',
        function ($rootScope, $window) {
          $window.open('//blog.treedom.net/' + $rootScope.ln, '_self');
        },
      ],
    })
    .state('blog.category', {
      url: '/:categorySlug',
      seo: 'blog',
      onEnter: [
        '$rootScope',
        '$window',
        function ($rootScope, $window) {
          $window.open('//blog.treedom.net/' + $rootScope.ln, '_self');
        },
      ],
    })
    .state('blog.author', {
      url: '/author/:authorId',
      seo: 'blog',
      onEnter: [
        '$rootScope',
        '$window',
        function ($rootScope, $window) {
          $window.open('//blog.treedom.net/' + $rootScope.ln, '_self');
        },
      ],
    })
    .state('blog.search', {
      url: '/search',
      seo: 'blog',
      onEnter: [
        '$rootScope',
        '$window',
        function ($rootScope, $window) {
          $window.open('//blog.treedom.net/' + $rootScope.ln, '_self');
        },
      ],
    })
    .state('error404', {
      url: '/404?previousPath',
      seo: 'error404',
      follow: true,
      views: {
        'page-content': {
          template: require('../html/templates/v-404.html'),
        },
      },
    })
    .state('error500', {
      url: '/500',
      views: {
        'page-content': {
          template: require('../html/templates/v-404.html'),
        },
      },
    })
    .state('community', {
      url: '/community',
      seo: 'community',
      follow: true,
      views: {
        'navbar-content': {
          template: navbar,
        },
        'page-content': {
          template: require('../html/templates/community.html'),
          controller: 'communityController as communityCtrl',
        },
        'footer-content': {
          template: footer,
        },
      },
    })
    .state('horoscope', {
      url: '/horoscope',
      seo: 'horoscope',
      follow: true,
      redirectTo: 'index',
    })
    .state('orange', {
      url: '/orange',
      params: {
        error: { squash: true, value: null },
      },
      seo: 'treecodeRedeem',
      follow: false,
      redirectTo: 'treecodeLanding',
    })
    .state('report2019', {
      url: '/report-2019',
      seo: 'index',
      fullRefreshNavigateTo: true,
    })
    .state('report2021', {
      url: '/report-2020',
      seo: 'index',
      fullRefreshNavigateTo: true,
    })
    .state('report2020', {
      url: '/annual-report-2019',
      seo: 'index',
      fullRefreshNavigateTo: true,
    })
    // Redirects & fallback routes
    .state('xmas', {
      url: '/xmas',
      redirectTo: 'index',
    })
    .state('xmas2017', {
      url: '/xmas2017',
      redirectTo: 'index',
    })
    .state('customercare', {
      url: '/customer-care',
      externalUrl: 'https://help.treedom.net/',
    })
    .state('page.aboutUs', {
      url: '/about-us',
      redirectTo: 'aboutUs',
    })
    .state('page.works', {
      url: '/works',
      redirectTo: 'aboutUs',
    })
    .state('page.contacts', {
      url: '/contacts',
      redirectTo: 'contacts',
    })
    .state('page.forbusiness', {
      url: '/forbusiness',
      externalUrl: 'https://business.treedom.net/',
    })
    .state('partyFavors', {
      url: '/party-favors',
      redirectTo: 'index',
    })
    .state('business', {
      url: '/business',
      fullRefreshNavigateTo: true,
    })
    .state('business.subscriptions', {
      url: '/subscriptions',
      fullRefreshNavigateTo: true,
    })
    .state('business.plant-a-tree', {
      url: '/plant-a-tree',
      fullRefreshNavigateTo: true,
    })
    .state('business.impacts', {
      url: '/impacts',
      fullRefreshNavigateTo: true,
    })
    .state('business.landing', {
      url: '/:slug',
      fullRefreshNavigateTo: true,
    })
    .state('forest', {
      url: '/forest/:eventslug',
      fullRefreshNavigateTo: true,
    })
    .state('forest.birthday', {
      url: '/birthday',
      fullRefreshNavigateTo: true,
    })
    .state('forest-create', {
      url: '/forest/create',
      fullRefreshNavigateTo: true,
    })
    .state('product-tour', {
      url: '/product-tour',
      fullRefreshNavigateTo: true,
    })
    .state('acmilan-landing', {
      url: '/partners/landing/acmilan',
      fullRefreshNavigateTo: true,
    })
    .state('enterprise', {
      url: '/enterprise',
      fullRefreshNavigateTo: true,
    })
    .state('landing', {
      url: '/landing/:slug',
      fullRefreshNavigateTo: true,
    })
    .state('celebration-modal', {
      url: '/celebration-modal/:registryId',
      fullRefreshNavigateTo: true,
    })
    .state('my', {
      url: '/my',
      fullRefreshNavigateTo: true,
    })
    .state('my.route', {
      url: '/:slug1',
      fullRefreshNavigateTo: true,
    })
    .state('my.route.route', {
      url: '/:slug2',
      fullRefreshNavigateTo: true,
    })
    .state('my.route.route.route', {
      url: '/:slug3',
      fullRefreshNavigateTo: true,
    });
}
